const dev = {
	// API_ENDPOINT_URL:'http://localhost:7080/app'
	API_ENDPOINT_URL: 'https://api.viddash.pro/app'
  };
  
  const prod = {
API_ENDPOINT_URL: 'https://api.viddash.pro/app'
  };
  
  const test = {
	API_ENDPOINT_URL: 'https://api.viddash.pro/app'
  };

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return dev
		case 'test':
			return dev
		default:
			break;
	}
}

export const env = getEnv()
